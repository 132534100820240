import MainTemplate from "../MainTemplate";
import "../../styles/index.scss";
import "./styles.scss";

function App() {
  return (
    <div className="App">
      <MainTemplate />
    </div>
  );
}

export default App;
