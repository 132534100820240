import { Link } from "react-router-dom";

import Box from "@mui/material/Box";
import Slide from "@mui/material/Slide";

import "./styles.scss";

function SideNav({ menu = [], back = null }) {
  return (
    <Box className="sidebar-container">
      <Link to="/">
        <h1>wei</h1>
      </Link>
      <Box sx={{ display: "flex" }}>
        <Slide
          direction="right"
          in={menu && menu.length}
          timeout={100}
          unmountOnExit
        >
          <Box className="main-nav">
            {menu &&
              menu?.sort((a,b) => a.priority - b.priority).map((item) => (
                <Link to={`${item.link}`}>
                  <li className="menu-item">{item.title}</li>
                </Link>
              ))}
          </Box>
        </Slide>
      </Box>
    </Box>
  );
}

export default SideNav;
