export default function(a, b) {
  // get year from projectDate which is like 2023-01-01
  const aYear = parseInt(a.projectDate?.split("-")[0]);
  const bYear = parseInt(b.projectDate?.split("-")[0]);
  
  if (isNaN(aYear))
    return 1;

  if (isNaN(bYear))
    return -1;

  return bYear - aYear;
};