import { Link } from "react-router-dom";

import "./styles.scss";

function App() {
  return (
    <Link to={"/home"}>
      <div className="splash-screen">
      </div>
    </Link>
  );
}

export default App;
