import { useEffect, useState } from "react";
import { useParams } from "react-router";
import Grid from "@mui/material/Grid";
import axios from "axios";
import { find } from "lodash";
import { ImageList, ImageListItem, ImageListItemBar } from "@mui/material";
import { marked } from "marked";
import { Link } from "react-router-dom";


import sortProjects from "../../utils/sortProjects";
import { API_BASE_URL } from "../../services/api";
import SideNav from "../../components/SideNav";
import { makeUrl } from "../../services/api";

import "../../styles/index.scss";
import "./styles.scss";

function MainTemplate({ children }) {
  const params = useParams();
  const [error, setError] = useState(null);
  const [selectedPage, setSelectedPage] = useState(null);
  const [projects, setProject] = useState([]);
  const [pages, setPages] = useState([]);

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/pages`)
      .then(({ data }) => setPages(data))
      .catch((error) => setError(error));
  }, []);

  useEffect(() => {
    const { nav } = params;
    if (nav) {
      const pageData = find(pages, { link: `/${nav}` });
      setSelectedPage(pageData);
    }
  }, [params, selectedPage, pages]);

  useEffect(() => {
    axios
      .get(makeUrl("/projects"))
      .then(({ data }) =>
        setProject(data.filter((item) => !!item.published_at))
      )
      .catch((error) => setError(error));
  }, []);

  function makeMenu(item) {
    item.link = `/${encodeURIComponent(item?.title?.toLowerCase())}`;
    return item;
  }

  function makeProjectMenu(item) {
    item.link = `/projects/${encodeURIComponent(item.title)}`;
    return item;
  }

  const sortedProjects = projects.sort(sortProjects);

  return (
    <Grid container>
      <Grid item xs={12} md={2}>
        <SideNav menu={pages.map(makeMenu)} />
      </Grid>
      <Grid item xs={12} md={10}>
        {selectedPage ? (
          <div
            className="page-content"
            dangerouslySetInnerHTML={{ __html: marked(selectedPage.content) }}
          />
        ) : (
          <div variant="masonry" className="image-grid">
            {sortedProjects.map(makeProjectMenu).map((project) => {
              return (
                <Link to={project.link}>
                  <ImageListItem style={{ height: "340px" }}>
                    {project?.stills[0] ? (
                      // <img src={project?.stills[0].url} style={{height: 'auto'}} />
                      <div
                        style={{
                          background: `url(${project?.stills[0].url})`,
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                          width: "100%",
                          height: "100%",
                        }}
                      />
                    ) : null}
                    
                    <ImageListItemBar title={project?.title}  />
                  </ImageListItem>
                </Link>
              );
            })}
          </div>
        )}
      </Grid>
    </Grid>
  );
}

export default MainTemplate;
