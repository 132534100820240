import Grid from "@mui/material/Grid";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import find from "lodash/find";
import { Button, ImageList, ImageListItem, Backdrop, Box } from "@mui/material";
import axios from "axios";
import sortProjects from "../../utils/sortProjects";
import useMobile from "../../utils/useMobile";

import SideNav from "../../components/SideNav";
import { makeUrl } from "../../services/api";

import "../Home/styles.scss";

function Projects() {
  const params = useParams();
  const [error, setError] = useState(null);
  const [projects, setProject] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };

  useEffect(() => {
    const projectData = find(projects, { title: params.nav });
    setSelectedProject(projectData);
  }, [params.nav, projects]);

  useEffect(() => {
    axios
      .get(makeUrl("/projects"))
      .then(({ data }) =>
        setProject(data.filter((item) => !!item.published_at))
      )
      .catch((error) => setError(error));
  }, []);

  function makeMenu(item) {
    item.link = `/projects/${encodeURIComponent(item.title)}`;
    return item;
  }

  const mobile = useMobile();
  const sortedProjects = projects.sort(sortProjects);

  return (
    <div className="App">
      <Backdrop
        sx={{ background: "rgba(0,0,0,.90)", zIndex: 9999 }}
        open={open}
        onClick={handleClose}
      >
        {selectedProject?.VideoURL && (
          <iframe
            id="myframe"
            title="vimeo-player"
            src={`${selectedProject?.VideoURL}&playsinline=0`}
            style={{
              display: "flex",
              width: "100vw",
              height: "100%",
              maxHeight: mobile ? "40vh" : "70vh",
            }}
            frameborder="0"
            allowscriptaccess="always"
            allowFullScreen="true"
          ></iframe>
        )}
      </Backdrop>
      <Grid container>
        <Grid item xs={12} md={3}>
          {sortedProjects && sortedProjects.length ? (
            <SideNav menu={sortedProjects.map(makeMenu)} back="/home" />
          ) : null}
        </Grid>
        <Grid item xs={12} md={7} style={{ marginTop: "20px" }}>
          {selectedProject?.stills.length > 0 && (
            <img
              src={selectedProject?.stills[0].url}
              loading="lazy"
              style={{
                maxWidth: "100%",
                maxHeight: "100%",
                objectFit: "contain",
              }}
            />
          )}
          {selectedProject?.title && <h1>{selectedProject?.title}</h1>}
          {selectedProject?.description && (
            <div className="description">{selectedProject?.description}</div>
          )}
          {selectedProject?.VideoURL && (
            <>{renderVideo(selectedProject?.VideoURL, handleToggle, mobile)}</>
          )}
          <ImageList variant="masonry" cols={mobile ? 1 : 2} gap={20}>
            {selectedProject?.stills
              .slice(1, selectedProject?.stills.length - 1)
              .map((still) => (
                <ImageListItem>
                  <img src={still.url} loading="lazy" />
                </ImageListItem>
              ))}
          </ImageList>
        </Grid>
      </Grid>
    </div>
  );
}

const isYT = (url) => {
  return url.includes("youtube");
};

// function that renders video iframe html if the url is a youtube url
function renderVideo(url, handleToggle, mobile) {
  if (isYT(url)) {
    return <div dangerouslySetInnerHTML={{ __html: url }} className="yt-container"/>;
  } else {
    return (
      <>
        <Box
          onClick={() => handleToggle()}
          sx={{
            width: "100%",
            height: mobile ? "320px" : "540px",
            position: "relative",
            marginBottom: mobile ? "-400px" : "-600px",
            cursor: "pointer",
          }}
        />
        <iframe
          src={`${url}&playsinline=0`}
          frameborder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowfullscreen
          style={{
            display: "flex",
            width: "100%",
            minHeight: mobile ? "400px" : "600px",
          }}
        ></iframe>
      </>
    );
  }
}

export default Projects;
