import {
  createBrowserRouter,
} from "react-router-dom";

import App from '../containers/Home'
import Splash from '../containers/Splash'
import Projects from '../containers/Projects'

const router = createBrowserRouter([
  {
    path: "/intro",
    element: <Splash />,
  },
  {
    path: "/:nav",
    element: <App />,
  },
  {
    path: "/projects",
    element: <Projects />,
  },
  {
    path: "/projects/:nav",
    element: <Projects />,
  },
  {
    path: "*",
    element: <App />,
  }
]);

export default router