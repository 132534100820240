import {useState, useEffect} from 'react';

// hook for checking if the user is on a mobile device
// returns true if the user is on a mobile device
// returns false if the user is not on a mobile device
export default function useMobile() {
  const [mobile, setMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth < 768) {
      setMobile(true);
    }
  }, []);

  return mobile;
}